.CoursesMobileALign {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.CoursesMobileALignText {
    color: black;
    font-size: 30px;
}

.CoursesMobileALignImg {
    height: 270px;
    object-fit: cover;
    background-size: cover;
}

.CoursesMobileButton {
    background-color: #ED145C;
    color: white;
    border: none;
    border-radius: 6px;
    letter-spacing: 1px;
    padding: 10px;
    font-size: 12px;
}   