.swiperAdjstAlign {
    height: 40%;
    width: 100%;

}

.swiperAdjstAlign2 {
    height: 100%;
    width: 100%;
}

.swiper_slider {
    display: flex;
    height: 100%;
    width: 100%;

}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiperInnerBoxer {
    transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.swiperInnerBoxer:hover {
    transform: scale(1.1);
}

.swiperInnerBox {
    width: 35%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.swiperInnerBoxImg {
    height: 250px;
}

.swiperInnerBoxer {
    height: 80%;
    width: 80%;
    border-radius: 20px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
}

.swiperInnerBoxSet {
    width: 35%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiperInnerBoxerText {
    font-size: 20px;
    color: black;
    text-align: start;
    font-weight: bold;
    letter-spacing: 2px;
}

.swiperInnerBoxerAdjst {
    display: flex;
    justify-content: space-between;
}

.swiperInnerBoxerImage {
    height: 60px;
}

.swiperInnerBoxerSecond {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.swiperInnerBoxer1SingleBox {
    height: 30px;
    width: 170px;
    border-radius: 12px;
    background-color: rgba(20, 146, 0, 0.20);
    display: flex;
    gap: 20px;
}

.swiperInnerBoxe2SingleBox {
    height: 30px;
    width: 200px;
    border-radius: 12px;
    background-color: rgba(20, 156, 255, 0.20);
    display: flex;
    gap: 20px;
}

.swiperInnerPtags {
    font-size: 15px;
    color: rgb(30, 29, 29);
    font-weight: bold;
}

.cta {
    position: relative;
    transition: all 0.2s ease;
    border: none;
    background: none;
    padding: 1px 13px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: rgba(237, 20, 92, 0.1);
    width: 30px;
    height: 30px;
    transition: all 0.3s ease;
    color: #fff;
}

.cta span {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: 'Quicksand', sans-serif;
    color: #ED145C;
}

.cta .swiperInnerBoxeSvg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #ED145C;
    stroke-width: 1;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.cta:hover:before {
    width: 100%;
    background: rgba(237, 20, 92, 0.1);
}

.cta:hover .swiperInnerBoxeSvg {
    transform: translateX(0);
}

.cta:active {
    transform: scale(0.95);
}

.swiperInnerBoxeBotton {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.swiperPreNext {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.custom-next-button {
    background-color: #ED145C;
    color: #f3f1f1;
    border-radius: 10px;
    height: 30px;
    border: none;
    width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

#swiperPreFontSet {
    font-size: 20px;
}

.custom-prev-button {
    background-color: #ED145C;
    color: #f3f1f1;
    border-radius: 10px;
    height: 30px;
    border: none;
    width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiperCoursePreNext {
    display: flex;
    justify-content: center;
    gap: 10px;
    height: 50px;
    width: 100%;
    align-items: flex-end;
}

.swiperCoursesSetting {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}

.swiperCoursesSecAlgn {
    height: 500px;
    width: 60%;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
}

.swiperCoursesSection {
    width: 340px;
    height: 100px;
    box-shadow: rgba(114, 111, 111, 0.25) 0px 14px 28px, rgba(104, 103, 103, 0.22) 0px 10px 10px;
    border-radius: 10px;
    display: flex;
    gap: 20px;
}

.swiperCourseSecSettings {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.swiperCourseSecSettings2 {
    display: flex;
    justify-content: center;
    width: 100%;
}

.swiperCourseSecSettings3 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.swiperCoursesimg1 {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.swiperCoursesSectionImg {
    width: 30%;
    height: 100%;
}

.swiperCoursesText {
    color: black;
    font-size: 15px;
    height: 20px;
    text-align: start;
    width: min-content;
    text-wrap: wrap;
    text-overflow: ellipsis;
    width: 100%;
}

.swiperCoursesInnerSec {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
}

.swiperCoursesinnexBtn {
    width: fit-content;
}



@media screen and (max-width: 1100px) {
    .cta span {
        font-size: 14px;
    }

    .swiperCoursesSection {
        width: 100%;
    }

    .cta:before {
        height: 20px;
        width: 20px;
        margin: 6px 6px;
    }

    .swiperInnerBox {
        width: 100%;
        padding: 15px;
        height: 90%;
    }

    .swiperInnerBoxer {
        width: 100%;
        height: 100%;
    }

    .swiperCoursesText {
        font-size: 15px;
    }

}

@media screen and (max-width: 750px) {
    .cta {
        padding: 2px 13px;
    }


    .swiperPreNext {
        justify-content: center;
    }

    .swiperCourseSecSettings {
        justify-content: center;
        width: 340px;
    }
}

@media screen and (max-width: 499px) {

    .swiperCoursesSecAlgn {
        width: 100%;
        padding: 20px;
    }
}