.loaderAlign {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.loader .loaderSpan {
    position: absolute;
    color: #fff;
    transform: translate(-50%, -50%);
    font-size: 38px;
    letter-spacing: 5px;
}

.loader .loaderSpan:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 0.3px #000000;

}

.loader .loaderSpan:nth-child(2) {
    color: #ED145C;
    -webkit-text-stroke: 1px #ED145C;
    animation: uiverse723 3s ease-in-out infinite;
}

@keyframes uiverse723 {

    0%,
    100% {
        clip-path: polygon(0% 45%, 15% 44%, 32% 50%,
                54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
    }

    50% {
        clip-path: polygon(0% 60%, 16% 65%, 34% 66%,
                51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
    }
}

@media screen and (max-width: 700px) {

    .loader .loaderSpan:nth-child(2) {
        text-align: center;
    }

    .loader .loaderSpan:nth-child(1) {
        text-align: center;
    }
}