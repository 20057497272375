.Navbar_align {
    width: 100%;
    display: flex;
    height: 60px;
    background-color: black;
    align-items: center;
    position: relative;
    z-index: 9999;
}


.Navbar_align2 {
    width: 100%;
    display: flex;
    height: 60px;
    background-color: rgb(255, 255, 255);
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.Navbar_adjst {
    padding: 00px 20px 00px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
}

.NavbarLogo {
    height: 40px;
    object-fit: cover;
}

.NavbarLogo2 {
    height: 40px;
    object-fit: cover;
}

.NavbarText {
    font-family: 'Quicksand', sans-serif;
    font-size: 17px;
    height: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    color: white;
    transition: 500ms ease-in-out;
}

.NavbarText2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 17px;
    height: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    color: black;
    font-weight: bold;
}

.NavbarText:hover {
    color: #ED145C;
    transform: scale(1.1);

}

.Navbaroptions {
    color: #ffffff;
    transition: 500ms ease-in-out;
}

.NavbarText2 {
    transition: 500ms ease-in-out;
    color: black;
}

.NavbarText2:hover {
    color: #ED145C;
}

.NavbarText2Selection:hover {
    color: #ED145C;
    border: none;
}

.NavbarSettingGap {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.NavbarSettingGap2 {
    display: flex;
    gap: 10px;

}

.NavbarSearch_icon {
    height: 30px;
    background-color: #ED145C;
    width: 30px;
    border-radius: 5px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.NavbarinpField {
    height: 30px;
    border-radius: 5px;
    width: 200px;
    letter-spacing: 1px;
    font-size: 14px;
    background-color: rgb(230, 227, 227);
    border: none;
}


#NavbarOGICon {
    color: white;
}

.Navbaroptions1 {
    color: black;
}

.NavbarText2Selection2:hover {
    color: #ED145C;
}

.NavbarText2Selection2 {
    border: none;
    font-weight: 600;
    width: min-content;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    font-family: 'Quicksand', sans-serif;
    width: 100px;
    transition: 500ms ease-in-out;
    letter-spacing: 1px;
    background-color: transparent;
    margin: 00px -4px;
}

.NavbarText2Selection {
    border: none;
    font-weight: 600;
    width: min-content;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    font-family: 'Quicksand', sans-serif;
    width: 100px;
    color: #ffffffde;
    transition: 500ms ease-in-out;
    letter-spacing: 1px;
    background-color: black;
    margin: 00px -4px;
}