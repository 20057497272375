.CoursesAlign {
    width: 100%;

}

.CoursesAlignSec {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.CoursesAlignSecImg {
    height: 400px;
}

.TCoursesAlignText {
    font-size: 35px;
    color: black;
    font-weight: bold;
    text-align: center;
    height: 100px;
    
}

.CoursesAlignTextSec {
    width: 100%;
}

.CoursesSectionAlign {
    display: flex;
}

@media screen and (max-width: 1100px) {
    .CoursesAlignSecImg {
        height: 300px;
    }

    .swiperCoursesText {
        font-size: 14px;
    }

    .CoursesAlign {
        height: auto;
    }

}