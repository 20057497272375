.AllCoursesWeb {
    display: block;
}

.AllCoursesmob {
    display: none;
}

@media screen and (max-width: 750px) {
    .AllCoursesWeb {
        display: none;
    }

    .AllCoursesmob {
        display: block;
    }

}