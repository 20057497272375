.ContactUsMobileAlign {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 50px;
}

.ContactUsMobileText {
    font-size: 30px;
    color: black;
    text-align: start;
}

.ContactUsMobilTextDiv {
    width: 100%;
}

.ContactUsMobileImgDiv {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ContactUsMobileImg {
    height: 300px;
}

.ContactUsMobileInpDiv {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.ContactUsMobileInp1 {
    height: 30px;
    background-color: rgb(232, 234, 236);
    border-radius: 6px;
    width: 50%;
    padding: 5px 5px;
    border: none;
    letter-spacing: 1px;
}

.ContactUsMobileInp2 {
    height: 30px;
    background-color: rgb(232, 234, 236);
    border-radius: 6px;
    width: 60%;
    padding: 5px 5px;
    border: none;
    letter-spacing: 1px;
}

.ContactUsMobileInp3 {
    height: 30px;
    background-color: rgb(232, 234, 236);
    border-radius: 6px;
    width: 70%;
    padding: 5px 5px;
    border: none;
    letter-spacing: 1px;
}

.ContactUsMobileSubBtn {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}