.AboutMobileAlignText {
    color: black;
    font-size: 25px;

}

.AboutMobileAlign {
    gap: 50px;
    display: flex;
    flex-direction: column;
}

.AboutMobileAlignText2 {
    color: #ED145C;
    font-size: 15px;
}

.AboutMobileSecond_secFont {
    font-size: 15px;
    color: black;
    line-height: 23px;
}

.AboutMobileAlignImage {
    height: 150px;
    object-fit: cover;
}
#MobileAboutUsLastIcon{
    color: #ED145C;
    font-size: 25px;
}
.AboutMobileImgAlign {
    width: 100%;
    display: flex;
    justify-content: center;
}