.CountriesAlign {
    width: 100%;
}

.CountriesAlignText {
    font-size: 30px;
    color: black;
    font-weight: 600;
    height: 100px;
}

.CountriesAlignDivAlign {
    display: flex;
    width: 100%;
}

.CountriesAlignDivSec {
    width: 20%;
    display: flex;
    justify-content: center;
}

.CountriesAlign2DivSec {
    width: 80%;
    padding: 20px 40px;
}

.CountriesAlignDivImg {
    height: 100%;
    width: 100%;
}

.CountriesAlign2DivText {
    font-size: 20px;
    color: rgb(63, 63, 63);
}

.CountriesAlign2DivText2 {
    font-size: 30px;
    color: #ED145C;
    font-weight: bold;
}

.CountriesAlign2DivFact {
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;

}

.CountriesAlign2DivInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.CountriesAlign2DivRnd {
    background-color: #ED145C;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

#CountriesICon {
    color: rgba(255, 255, 255, 0.84);
    font-size: 30px;

}

.mobileCountries2DivText {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.661);
    font-weight: bold;
    height: 10px;
    letter-spacing: 1px;

}

.mobileCountries2DivText2 {
    font-size: 17px;
    color: black;
}

.CountriesAlignDivImg {
    border-radius: 20px;
}

.mobileCountries2DivButtonAlign {
    display: flex;
    justify-content: center;
}

.CountriesPtagColor {
    color: #ED145C;
    font-weight: bolder;
    font-size: 40px;
}

.ContactUsAlignSecText {
    color: red;
    font-size: 10px;
}

/* ImageWithGradient.css */

@media screen and (max-width: 850px) {
    .HidePage {
        display: none;
    }

    .CountriesAlignText {
        height: auto;
    }

    .CountriesAlignDivAlign {
        flex-direction: column;
        gap: 30px;
    }

    .CountriesAlignDivSec {
        width: 100%;
    }

    .CountriesAlignDivImg {
        height: 500px;
        object-fit: cover;
        border-radius: 20px;
    }

    .CountriesAlign2DivFact {
        padding: 0px;
    }

    .CountriesAlign2DivSec {
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}