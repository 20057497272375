.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.swiper-slide img {
    display: block;
    object-fit: cover;
}

.InfinityScrollAligneset {
    width: 100%;
    padding: 50px;
}

.InfinintyScrollptag {
    font-size: 20px;
    letter-spacing: 1px;
    height: 15px;
    font-family: "Gideon Roman", serif;
    text-overflow: ellipsis;
    color: white;
}

.InfinityScrollImg {
    width: 100%;
    height: 100%;
    background-image:
        linear-gradient(to bottom, rgba(17, 17, 18, 0.627), rgba(0, 0, 0, 0.742)),
        url("../../../public/Images//toronto-sunrise.jpg");
    object-fit: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.InfinityScrollText {
    font-size: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
}

.InfinityScrollText2 {
    font-size: 17px;
    text-align: center;
    width: 40%;
}

.InfinityScrollSlide {
    height: 100px;
    display: flex;
    align-items: center;
    background-color: rgba(240, 248, 255, 0.379);
    padding: 20px;

}

.InfinityScrollImgAlign {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.InfinityScrollImgAlignKnow {
    display: flex;
    justify-content: end;
    width: 30%;
}

.InfinityScrollImgAlignSec {
    width: 30%;
}

.InfinityScrollImgAlignSec1 {
    width: 30%;
}

.InfinityScrollSlideImg {
    height: 100%;
    object-fit: cover;
    background-size: cover;
}

.InfinityScrollSwiper {
    width: 100%;
}

.InfinityScrollSlideAdjst {
    width: 200px;
    height: 100px;
    display: flex;
    align-items: center;
}

.InfinityScrollswiperslide {
    height: 100px;
    width: 100px;

}

.swiper-slide {
    width: 200px;
}

@media screen and (max-width: 750px) {
    .InfinityScrollAligneset {
        height: auto;
    }

    .InfinityScrollText2 {
        width: 100%;
    }

    .InfinityScrollAligneset {
        padding: 0px;
    }

    .InfinityScrollImgAlignKnow {
        display: none;
    }
    .InfinityScrollImgAlignSec1{
        display: none;
    }
    .InfinityScrollImgAlignSec{
        width: 100%;
    }
}