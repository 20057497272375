.FooterAlignment {
    width: 100%;
    background-color: black;
    display: flex;
}

.FooterAlignmentSec {
    gap: 40px;
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
    align-items: center;
}

.FooterAlignmentImgs {
    height: 100px;
}

.FooterAlignmentIText {
    color: #ED145C;
    font-size: 20px;
    height: 20px;
}

.FooterAlignmentIText2 {
    color: rgba(255, 255, 255, 0.673);
    font-size: 17px;
    line-height: 40px;
}

.FooterAlignmentIText3 {
    font-size: 17px;
    color: rgba(255, 255, 255, 0.618);
}

.FooterAlignmentSec2 {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.FooterAlignmentSec3 {
    display: flex;
    flex-direction: column;
    width: 40%;
    gap: 20px;
}

.FooterAlignmentDiv {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.FooterAlignFInalSecAlign {
    display: flex;
    justify-content: space-around;
    text-align: center;
    background-color: black;
    align-items: center;
    height: 100px;
}

.FooterAlignSec3Social {
    display: flex;
    width: 100%;
    gap: 30px;
    justify-content: center;
}

.footerMapStyle {
    height: 100%;
    width: 100%;
}

.google-map-code {
    height: 200px;
}

#FooterAlignSec3SocialIcon {
    font-size: 40px;
    color: #ED145C;
    border-radius: 50%;
}

.FootercopyRightsptag {
    color: rgba(255, 255, 255, 0.708);
    font-size: 15px;
}

.FootercopyRights {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: black;
    flex-direction: column;
    align-items: center;
}

.FootercopyRightsChaavie {
    color: rgba(255, 255, 255, 0.83);
    border-bottom: 1px solid blue;
    transition: 0.5s;
}

.FootercopyRightsChaavie:hover {
    color: rgba(0, 47, 255, 0.83);

}

.FooterAlignmentIText3 {
    font-size: 20px;
    color: #ED145C;
}

.FooterAlignmentImob {
    display: none;
}

.FooterAlignmentButton {
    width: 120px;
    padding: 5px;
    color: #ED145C;
    border: 1px solid #ED145C;
    border-radius: 6px;
    background-color: transparent;
    transition: 1s;
}

.FooterAlignmentButton:hover {
    background-color: #ED145C;
    color: white;
}

@media screen and (max-width: 1000px) {
   
}

@media screen and (max-width: 750px) {
    .FooterAlignment {
        flex-direction: column;
    }

    .FooterAlignmentImob {
        display: block;
        color: white;
        font-size: 20px;
        text-align: start;
    }

    .FooterAlignmentSec {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .FooterAlignFInalSecAlign {
        flex-direction: column;
        gap: 10px;
        height: auto;
    }

    .FooterAlignmentSec {
        align-items: flex-start;
    }

    .FooterAlignmentImgs {
        height: 50px;
    }

    .FootercopyRightsptag {
        color: rgba(255, 255, 255, 0.708);
        font-size: 17px;
        padding: 10px;
        text-align: center;
    }

    .FooterAlignmentImgAlign {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .FooterAlignmentSec2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .FooterAlignmentSec3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }



    .FooterAlignSec3Social {
        justify-content: center;
    }
}