.CounterBarSett {
    height: 90px;
    width: 80%;
    background-color: #2B2B2B;
    border-radius: 20px;
    margin-top: -50px;
    display: flex;
}

.CounterCntntSetting {
    width: 230px;
}

.CounterText {
    font-size: 25px;
    color: #ED145C;
    height: 15px;
    text-align: center;

}

.CounterBarDis {
    display: flex;
    justify-content: space-around;
    width: 50%;
    height: auto;
    align-items: center;
}

.CounterTextNm2 {
    font-size: 19px;
    color: #FFFFFF;
    height: 10px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .CounterText {
        font-size: 17px;
    }

    .CounterTextNm2 {
        font-size: 12px;
    }

    .CounterBarSett {
        flex-direction: column;
        height: auto;
        background-color: transparent;
        width: 100%;
        gap: 20px;
        margin-top: 0px;
    }

    .CounterCntntSetting {
        width: 230px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .CounterBarDis {
        width: 100%;
        background-color: black;
        border-radius: 20px;
        height: 100px;
        padding: 10px 10px;
    }


}

@media screen and (max-width: 1100px) {
    .CounterTextNm2 {
        font-size: 15px;
    }
    .CounterText{
        font-size: 20px;
    }
}
@media screen and (max-width: 500px) {
    .CounterTextNm2{
        font-size: 10px;
    }
    .CounterText{
        font-size: 20px;
    }
}