.AboutUsAlign {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: white;
    position: relative;
}

.AboutSecAlign {
    display: flex;
    width: 100%;
}

.AboutUsSecset {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.AboutUsSecsetImg {
    height: 60%;
}

.AboutUsSecsetCoughtsSec {
    height: 150px;
    width: 350px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    background-color: #f73375;
    margin: 00px 80px;
}

.AboutUsSecsetBoxAlign {
    width: 100%;
    justify-content: flex-end;
    display: flex;
}

.AboutUsInnerSec {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    height: 50px;
}

.AboutUsInnerSein {
    height: 50px;
    width: 50px;
    background-color: white;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 20px;
}

a {
    text-decoration: none;
}

.AboutUsInnerCoughtFont2 {
    font-size: 15px;
    text-align: end;
}

.AboutUsInnerCoughtFont {
    font-size: 15px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}

.AboutUsSecondCoughtFont {
    font-size: 30px;
    font-weight: bolder;
    color: black;
    text-align: center;
}

.AboutUsInnerSecond_sec {
    color: black;
    font-size: 20px;
}

.AboutUsSecsetCoughtsSec {
    transition: transform .5s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.AboutUsSecsetCoughtsSec:hover {
    transform: scale(1.1);
}

.AboutUsAlignLetsButton {
    width: 200px;
    border-radius: 6px;
    border: none;
    background-color: #f73375;
    height: 40px;
    color: rgb(245, 242, 242);
    letter-spacing: 2px;
    transition: 0.5s;

}

.AboutUsAlignLetsButton:hover {
    transform: scale(1.1);
}





.AboutUsAlignLets {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.AboutUsLastContent {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;

}

#AboutUsLastIcon {
    color: #f73375;
    font-size: 30px;
}

.AboutUsSecondTextSet {
    height: 200px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.AboutUsInnerSecond_secFont {
    font-size: 17px;
    color: rgb(0, 0, 0);
    font-weight: bold;
}

.AboutUsLastFlexAlgn {
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 1100px) {
    .AboutUsSecsetImg {
        height: 15rem;
    }

    .AboutUsInnerCoughtFont {
        font-size: 13px;
    }
    .AboutUsInnerSecond_sec{
        font-size: 15px;
    }
    .AboutUsInnerSecond_secFont{
        font-size: 15px;
    }
}
@media screen and (max-width: 1280px) {
    .AboutUsSecsetImg{
        height: 200px;
    }
    .AboutUsInnerSecond_secFont{
        font-size: 13px;
    }
    .AboutUsInnerSecond_sec{
        font-size: 14px;
    }
    .AboutUsSecset{
        gap: 20px;
    }
    .AboutUsAlignLetsButton{
        font-size: 15px;
    }
    .AboutUsSecsetCoughtsSec{
        height: 120px;
    }
    .AboutUsInnerCoughtFont{
        font-size: 10px
    }
    .AboutUsInnerSein{
        height: 30px;
        width: 30px;
    }
    .AboutUsInnerSec{
        height: 30px;
    }
    .AboutUsSecondTextSet{
        height: 100px;
    }
}