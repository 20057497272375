.AdmissionAlign {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 60px;
}

.AdmissionCardSec1,
.AdmissionCardSec2 {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.AdmissionCardSec1,
.AdmissionCardSec2,
.AdmissionCardSec1 > .AdmissionCardFront,
.AdmissionCardSec2 > .AdmissionCardFront {
    min-height: 500px; /* Adjust this value as needed */
}

.AdmissionTextField {
    font-size: 35px;
    color: #ED145C;
    text-align: center;
}

.AdmissionCardSec1 {
    background-image:
        linear-gradient(to bottom, rgba(17, 17, 18, 0.627), rgba(0, 0, 0, 0.742)),
        url("../../../public/Images/2808170_18433.jpg");
    object-fit: cover;
    background-size: cover;
    gap: 10px;
    border-radius: 6px;
}

.AdmissionCardSec2 {
    background-image:
        linear-gradient(to bottom, rgba(17, 17, 18, 0.627), rgba(0, 0, 0, 0.742)),
        url("../../../public/Images/group-students-celebrating-graduation-together-wearing-face-masks.jpg");
    object-fit: cover;
    background-size: cover;
    gap: 10px;
    border-radius: 6px;
}

.AdmissionCardFrontText {
    font-size: 20px;
    color: #ED145C;
    height: 15px;
    letter-spacing: 1px;
}

.AdmissionCardFront {
    position: absolute;
    z-index: 9999;
    width: 400px;
    height: 400px;
    display: flex;
    align-items: flex-end;
    gap: 20px;
}

.AdmissionCardFrontText {
    color: #ED145C;
    font-size: 29px;
    height: 50px;
    font-weight: bold;
}

.AdmissionCardFrontLi {
    font-size: 19px;
    color: rgba(255, 255, 255, 0.818);
    text-align: start;
    line-height: 35px;
}

@media screen and (max-width: 850px) {
    .AdmissionAlign {
        flex-direction: column;
    }

    .AdmissionCardSec1,
    .AdmissionCardSec2 {
        width: 100%;
    }
}
