.BlogSecFlexSet {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
}

.BlogSecAlign {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.BlogSec {
    display: flex;
    height: 200px;
    width: 600px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 15px;
    padding: 10px;
}

.BlogSecInnerSec {
    width: 50%;
    padding: 10px;
}

.BlogSecSetTextField {
    font-size: 35px;
    color: black;
    letter-spacing: 1px;
    font-weight: bold;
}

.BlogSecSetText {
    display: flex;
    gap: 10px;
}

.BlogSecSetTextFieldSpan {
    font-size: 35px;
    color: #ED145C;
    letter-spacing: 1px;
    font-weight: bold;

}

.BlogSecInnerSecText {
    font-size: 17px;
    color: black;
    font-weight: bold;
}

.BlogSecInnerSecText2 {
    font-size: 15px;
    color: black;
}

.VlogWebNAv {
    display: block;
}

.VlogMobNav {
    display: none;
}

@media screen and (max-width: 700px) {
    .VlogWebNAv {
        display: none;
    }

    .VlogMobNav {
        display: block;
    }
}