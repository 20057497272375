.three-container {
    height: 300px;
    width: 300px;
}

#yourThreeJSContainer {
    top: -150px;
    right: 100px;
    z-index: -9999;
}

.three-container {
    touch-action: none;
    /* Disable touch zooming on mobile devices */
    user-zoom: fixed;
    position: absolute;
    /* Disable zooming on desktop browsers */
}

@media screen and (max-width: 1000px) {
    #yourThreeJSContainer {
        margin: -100px -200px;

    }
}

@media screen and (max-width: 1000px) {
    .three-container {
        display: none;
    }
}