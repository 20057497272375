.main_defaultStyle {
    color: black;
    font-size: 17px;
    font-family: 'Quicksand', sans-serif;
    height: 100%;
    width: 100%;
}
.Main_IMg_style{
    width: 100%;
    height: 100%;

}