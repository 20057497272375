.StudentsLove {
    width: 100%;
    height: 70vh;
    display: flex;
    padding: 50px 50px;
}

.StudentsLoveSec {
    width: 50%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.StudentsLoveSec2 {
    width: 50%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: white;
}

.video-wrapper {
    height: 70%;
    width: 90%;
}

.StudentsLoveSecYoutube {
    border-radius: 15px;
}

.StudentsLoveText {
    color: rgb(5, 5, 5);
    font-size: 35px;
    font-weight: 600;
}

.StudentsLoveText2 {
    color: #ED145C;
    font-size: 25px;
}

.BlogSecAlign {
    width: 100%;

}

.StudentsLoveFinal {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.StudentsLoveText3 {
    font-size: 22px;
    color: rgba(0, 0, 0, 0.831);
}

.StudentsLoveIframe {
    border-radius: 20px;
}

.StudentsLoveBtn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.StudentsSliders {
    padding: 10px;
}

@media screen and (max-width: 750px) {
    .StudentsLove {
        flex-direction: column;
    }

    .StudentsLoveSec {
        width: 100%;
    }

    .StudentsLoveSec2 {
        width: 100%;
    }

    .StudentsLove {
        padding: 00px;
    }
}