.MobileCountryAlign {
    display: block;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mobileCountriesAlign {
    width: 100%;
}



.mobileCountriesAlignText {
    font-size: 40px;
    color: black;
    font-weight: 900;
    height: 100px;
}

.mobileCountriesAlignDivAlign {
    display: flex;
    width: 100%;
}

.mobileCountriesGapSet {
    display: flex;
    flex-direction: column;
    gap: 60px;
    justify-content: center;

}

.mobileAdmissionUnder {
    border-bottom: 1px solid #ED145C;
    
}

.mobileAdmissionReqTextAlign {
    font-size: 30px;
    color: black;
    font-weight: bold;
    padding: 40px 0px 0px 0px;
    letter-spacing: 1px;
    text-align: center;

}

.mobileCountriesAlignDivSec {
    width: 20%;
    display: flex;
    justify-content: center;
}

.mobileCountriesAlign2DivSec {
    width: 80%;
    padding: 20px 40px;
}

.mobileCountriesAlignDivImg {
    height: 100%;
    width: 100%;
}

.mobileCountriesAlign2DivText {
    font-size: 20px;
    color: rgb(63, 63, 63);
}

.mobileCountriesAlign2DivText2 {
    font-size: 30px;
    color: #ED145C;
    font-weight: bold;
}

.mobileCountriesAlign2DivFact {
    display: flex;
    justify-content: space-between;
    padding: 20px 50px;

}

.mobileCountriesAlign2DivInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.mobileCountriesAlign2DivRnd {
    background-color: #ED145C;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}

#CountriesICon {
    color: rgba(255, 255, 255, 0.84);
    font-size: 30px;

}

.Countries2DivText {
    font-size: 17px;
    color: rgba(0, 0, 0, 0.661);
    font-weight: bold;
    height: 10px;

}

.Countries2DivText2 {
    font-size: 13px;
    color: black;

}

.mobileCountriesAlignDivImg {
    border-radius: 20px;
}

/* ImageWithGradient.css */

@media screen and (max-width: 850px) {
    .HidePage {
        display: none;
    }

    .mobileCountriesAlignText {
        height: auto;
    }

    .mobileCountriesAlignDivAlign {
        flex-direction: column;
        gap: 30px;
    }

    .mobileCountriesAlignDivSec {
        width: 100%;
    }

    .mobileCountriesAlignDivImg {
        height: 500px;
        object-fit: cover;
        border-radius: 20px;
    }

    .mobileCountriesAlign2DivFact {
        padding: 0px;
    }

    .mobileCountriesAlign2DivFact {
        flex-direction: column;
        gap: 60px;
    }

    .mobileCountriesAlign2DivRnd {
        height: 100px;
        width: 100px;
    }


    #CountriesICon {
        font-size: 70px;
    }

    .mobileCountriesAlign2DivSec {
        padding: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}