.ExpertAdviceAdjst {
    width: 60%;
    border-radius: 20px;
    background-color: black;
    padding: 20px 20px;
}

.ExpertAdviceText {
    color: rgba(255, 255, 255, 0.762);
    font-size: 20px;
}

.ExpertAdviceSec1 {
    width: 50%;
}

.ExpertAdviceSec {
    width: 50%;
}

.ExpertAdviceButton {
    height: 35px;
    width: 150px;
    color: rgba(255, 255, 255, 0.77);
    background-color: #ED145C;
    letter-spacing: 2px;
    border-radius: 6px;
    border: none;
}

.ExpertAdviceAdjstImg {
    height: 80px;
    margin: -200px 00px;
}
#swiperInnerBoxeIocns{
    font-size: 25px;
    color: #ED145C;
}

.ExpertAdviceAdjstFinal {
    display: flex;
    width: 100%;
    height: auto;
    gap: 100px;
}

.ExpertAdviceBTnDiv {
    display: flex;
    justify-content: flex-start;
}


@media screen and (max-width: 1100px) {
    .ExpertAdviceText {
        font-size: 15px;
    }

    .ExpertAdviceText {
        font-size: 15px;

    }

    .ExpertAdviceAdjst {
        height: auto;
    }
}

@media screen and (max-width: 750px) {
    .ExpertAdviceAdjst {
        width: 100%;
        height: 100%;
    }

    .ExpertAdviceSec {
        width: 100%;
        background-color: black;
        border-radius: 20px;
        padding: 20px 20px;
    }

    .ExpertAdviceSec1 {
        width: 100%;
        background-color: black;
        border-radius: 20px;
        padding: 20px 20px;

    }

    .ExpertAdviceAdjstFinal {
        flex-direction: column;
        background-color: transparent;
        gap: 20px;
    }

    .ExpertAdviceAdjstFinal {
        flex-direction: column;
        background-color: transparent;
        gap: 20px;
    }

    .ExpertAdviceAdjst {
        padding: 00px;
        background-color: transparent;
    }

    .ExpertAdviceText {
        font-size: 17px;
    }

    .ExpertAdviceBTnDiv {
        display: flex;
        justify-content: center;
    }
}