.SelectionAlign {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectionSec {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.SelectionButtonSec {
  width: 100%;
  display: flex;
}

.SelectionButtonField2 {
  height: 100%;
  width: 100%;
  background-color: transparent;
  color: #ffffffe8;
  border: none;
  font-size: 19px;
  letter-spacing: 1px;
}

.SelectionButtonField {
  height: 90%;
  width: 100%;
  background-color: #ffffff;
  color: #ed145ce8;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.102) 0px 25px 20px -20px inset;
  border-bottom: 5px solid white;
  position: relative;
  z-index: 999;
  margin-top: 0.5px;
  font-size: 19px;
  border-radius: 40px 40px 00px 00px;
  font-weight: 600;
  letter-spacing: 1px;
}

.SelectionButtonDiv {
  background-color: #ed145c;
  height: 100px;
  width: 25%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;


}

.SelectionButtonDiv2 {
  background-color: #ed145ce8;
  height: 100%;
  width: 25%;
  border-radius: 00px 00px 40px 40px;
}


.NextButton {
  animation: bounce 0.5s ease;
}

.SelectionUnderField {
  border: 9px solid #ed145ce8;
  border-top: none;
  width: 100%;
  height: 100%;

  border-radius: 0px 0px 40px 40px;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-15px);
  }

  60% {
    transform: translateY(-10px);
  }
}


@keyframes beatingHeart {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.05);
  }

  30% {
    transform: scale(1);
  }

  45% {
    transform: scale(1.05);
  }

  60% {
    transform: scale(1);
  }
}