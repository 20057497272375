.TopCoursesAlign {
    height: auto;
    width: 100%;
    padding: 50px;
}

.TopCourseText {
    text-align: start;
    font-size: 35px;
    color: #000000;
    letter-spacing: 2px;
    height: 100px;
    font-weight: bold;
}

.TopCourseText2 {
    color: #ED145C;
}

.TopCoursesTileAlign {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
}

.TopCoursesTilesSet {
    width: 300px;
    height: 160px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;

}

.TopCoursesTileImg {
    height: 100%;
    width: 40%;
}

.TopCoursesTileImgCom {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;

}

.TopCoursesTileTextDiv {
    width: 100%;
    height: 100%;
    align-items: center;
    position: absolute;
    text-align: center;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 5;
    -moz-line-clamp: 5;
    -ms-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
}

.TopCoursesTileText2 {
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    display: flex;
    word-wrap: break-word;
    white-space: normal;
    height: 100%;
}

.TopCoursesTileText {
    color: rgb(255, 255, 255);
    font-size: 18px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.image-container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
}

.gradient-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.385), rgba(0, 0, 0, 0.634));
    border-radius: 6px;

}

@media screen and (max-width: 750px) {
    .TopCoursesTilesSet {
        width: 100%;
    }

    .TopCoursesAlign {
        padding: 20px;
    }
}