.Home_align {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: black;
}

.Home_VideoPlayer {
    width: 100%;
    position: absolute;
    z-index: -9999999;
}

.Home_Video {
    width: 100%;
    height: 93vh;
    object-fit: cover;
    overflow: hidden;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.158), rgba(0, 0, 0, 0.151));
    pointer-events: none;
    z-index: 1;
}

.Home_Video {
    position: relative;
}

.Home_Video::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
    /* Adjust the opacity as needed */
    pointer-events: none;
    /* This allows clicks to go through the overlay to the video */
}

.homeSec_setting {
    display: flex;
    width: 40%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.homeSec_settin2 {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.homeSection {
    width: 50%;
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
}

.Home_font1 {
    font-size: 45px;
    font-weight: bold;
    width: 90%;
}

p {
    font-family: 'Quicksand', sans-serif;
    color: #FFFFFFC7;
}

.HomeSpan {
    color: #ED145C;
}

.HomeButton1 {
    width: 200px;
    letter-spacing: 2px;
    height: 40px;
    border-radius: 6px;
    background-color: #ED145C;
    color: white;
    font-family: 'Quicksand', sans-serif;
    position: relative;
    z-index: 99;
    border: none;
}

.Home_fontBtnalign {
    height: 400px;
    margin-top: -80px;

}

.Home_fontBtalign {
    display: flex;
}

.homeSection2 {
    display: flex;
    align-items: center;

}

.homeSectionImage {
    height: 500px;
}

.Home_lastSecAlign {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 80%;

}

.Home_lastText {
    font-size: 20px;
    letter-spacing: 2px;
    color: #FFFFFFC7;
}

.Home_fontBtalignS {
    display: flex;
    width: 100%;
    height: 100%;
}

.Home_fontBtalignS {
    gap: 60px;
}

.Home_FlightAlgn {
    display: flex;
    position: absolute;
    width: 90%;
}

.home_sectionSettings {
    height: 80%;
    display: flex;
}

.Home_lastButtomCntnt {
    width: 100%;
    justify-content: center;
    display: flex;
}


@media screen and (max-width: 1158px) {
    .Home_font1 {
        font-size: 30px;
    }

    .Home_lastText {
        font-size: 13px;
    }

    .Home_fontBtalignS {
        gap: 0;
    }

    .CounterText {
        font-size: 10px;

    }

    .homeSectionImage {
        height: 400px;
    }
}

@media screen and (max-width: 1358px) {
    .homeSectionImage {
        height: 400px;
    }

    .Home_font1 {
        font-size: 35px;
    }
}